<template>
  <b-sidebar id="notificationSidebar" v-model="show" title="Benachrichtigungen" lazy right width="400px"
             backdrop backdrop-variant="transparent" :bg-variant="bgVariant" :text-variant="textVariant"
             header-class="text-center" sidebar-class="sidebar-background border-left sidebar-border-white"
             @shown="onShow" @hidden="onHidden">
    <div class="px-3 py-2">
      <b-spinner v-if="loadingNotifications" class="mt-3" :variant="spinnerVariant" style="width: 3rem; height: 3rem;"/>
      <div v-else-if="notifications.length > 0">
        <Notification v-for="notifcation in notifications" :key="notifcation.id" class="mb-3"
                      :notification="notifcation" @refresh="refresh"/>
      </div>
      <div v-else class="text-center">
        <span class="no-data">Keine Benachrichtigungen vorhanden</span>
      </div>
    </div>
    <template #footer>
      <div class="align-items-center px-3 py-2">
        <b-button size="sm" @click="deleteAllNotifications" :disabled="notifications.length < 1">
          Alle Benachrichtigungen entfernen
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import axios from "axios";
import {putRequest} from "@/modules/requests";
import Notification from "@/components/sidebar/Notification";
import {mapGetters} from "vuex";

export default {
  name: "NotificationSidebar",
  props: ['show'],
  components: {
    Notification
  },
  data() {
    return {
      notifications: [],
      loadingNotifications: true
    }
  },
  methods: {
    onShow() {
      this.getNotifications()
      this.setReadAll()
    },
    onHidden() {
      this.notifications = []
      this.$emit('close')
    },
    refresh() {
      this.getNotifications()
    },
    async getNotifications() {
      this.loadingNotifications = true;
      await axios.get('notifications/all')
          .then((response) => {
            this.notifications = response.data
            this.loadingNotifications = false;
          })
          .catch(() => {
            this.loadingNotifications = false;
          })
    },
    async setReadAll() {
      await axios.put('notifications/read-all')
          .then(() => {
            this.$emit('all-read')
          })
    },
    async deleteAllNotifications() {
      await putRequest('notifications/delete-all', null, this, 'Benachrichtigungen alle entfernt.', 'Benachrichtigungen konnten nicht entfernt werden.')
          .then(() => {
            this.refresh()
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    bgVariant() {
      if(this.userThemeId === 2) { return 'light' }
      else return 'grey-900'
    },
    textVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'light'
    },
    spinnerVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'white'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Header.scss';
@import '../../assets/styles/Sidebar.scss';
</style>
